import '../public/global.css'

import cx from 'classnames'
import { LazyMotion, domAnimation, AnimatePresence } from 'framer-motion'
import { type AppProps } from 'next/app'
import Head from 'next/head'
import { Router } from 'next/router'
import Script from 'next/script'
import { type ReactNode, useEffect, useContext, useRef } from 'react'
import { useEffectOnce } from 'usehooks-ts'

import { type SanityPage } from '@data/sanity/queries/types/page'
import {
  type SanityGeneralSettings,
  type SanitySiteFragment,
} from '@data/sanity/queries/types/site'
import { triggerPageviewEvent } from '@lib/analytics'
import { pageTransitionSpeed } from '@lib/animate'
import { epilogueFont } from '@lib/fonts'
import { type Locale } from '@lib/language'
import { LanguageContextProvider } from '@lib/language-context'
import { ShopContextProvider } from '@lib/shop-context'
import { SiteContext, SiteContextProvider } from '@lib/site-context'
import { StringsContextProvider } from '@lib/strings-context'

import RouteChangeProgressBar from '@components/route-change-progress-bar'

interface TransitionOptions {
  shallow?: boolean
  locale?: string | false
  scroll?: boolean
}

interface NextHistoryState {
  url: string
  as: string
  options: TransitionOptions
}

interface AppPageProps {
  draftMode: boolean
  draftToken?: string
  locale: Locale
  site: SanitySiteFragment | null
  page: SanityPage | null
}

type DefaultAppProps = AppProps<AppPageProps>

type CustomAppProps = DefaultAppProps & {
  pageProps: AppPageProps
}

type SiteProps = Pick<DefaultAppProps, 'router'> & {
  pageProps: AppPageProps
  children: ReactNode
}

interface ExternalScriptsProps {
  settings?: SanityGeneralSettings
}

/**
 * Loads external scripts using next/script.
 */
const ExternalScripts = ({ settings }: ExternalScriptsProps) => {
  const nonce =
    typeof window !== 'undefined'
      ? document
          .querySelector('[property="csp-nonce"]')
          ?.getAttribute('content')
      : null

  if (!nonce) {
    return null
  }

  const linkedDomains: string[] = []

  return (
    <>
      {settings?.cookieBotId && (
        <Script
          id="Cookiebot"
          nonce={nonce}
          src="https://consent.cookiebot.com/uc.js"
          strategy="afterInteractive"
          data-cbid={settings.cookieBotId}
          data-blockingmode="auto"
        />
      )}

      {settings?.gtmContainerId && (
        <>
          <Script
            id="google-tag-manager-variables"
            nonce={nonce}
            strategy="afterInteractive"
          >
            {`
          window['dataLayer'] = window['dataLayer'] || []
          window['dataLayer'].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
        `}
          </Script>
          <Script
            id="google-tag-manager"
            src={`https://www.googletagmanager.com/gtm.js?id=${settings.gtmContainerId}`}
            nonce={nonce}
            strategy="lazyOnload"
          />
        </>
      )}

      {settings?.analyticsId && (
        <>
          <Script
            id="google-analytics-variables"
            nonce={nonce}
            strategy="afterInteractive"
          >
            {`
              window['GoogleAnalyticsObject'] = 'ga'
              window['ga'] = window['ga'] || function () {
                window['ga'].q = window['ga'].q || []
                window['ga'].q.push(arguments)
              }
              window['ga'].l = 1 * new Date()

              window.ga('create', '${
                settings.analyticsId
              }', 'auto', { allowLinker: true })
              window.ga('require', 'linker')
              window.ga('linker:autoLink', ${JSON.stringify(linkedDomains)})

              window.ga('send', 'pageview')
            `}
          </Script>
          <Script
            id="google-analytics"
            src="https://www.google-analytics.com/analytics.js"
            nonce={nonce}
            strategy="lazyOnload"
          />
        </>
      )}

      {settings?.facebookPixelId && (
        <>
          <Script
            id="facebook-pixel-variables"
            nonce={nonce}
            strategy="afterInteractive"
          >
            {`
              if (!window.fbq) {
                const n = function () {
                  n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
                }
                window.fbq = n

                if (!window._fbq) {
                  window._fbq = n
                }

                n.push = n
                n.loaded = true
                n.version = '2.0'
                n.queue = []

                window.fbq('init', '${settings.facebookPixelId}')
                window.fbq('track', 'PageView')
              }
            `}
          </Script>
          <Script
            id="facebook-pixel"
            src="https://connect.facebook.net/en_US/fbevents.js"
            nonce={nonce}
            strategy="lazyOnload"
          />
        </>
      )}
    </>
  )
}

/**
 * Add new position to scroll positions.
 */
const addScrollPosition = (
  positions: Record<string, number>,
  locale: Locale,
  url: string,
  position: number
) => {
  const key = `${locale}:${url}`
  const alternativeKey = `${locale}:/${locale}${url.replace(/\/+$/g, '')}`

  return {
    ...positions,
    [key]: position,
    [alternativeKey]: position,
  }
}

/**
 * Router event handler hook.
 */
const useRouterEvents = (router: Router, locale: Locale) => {
  const { toggleIsRouteChanging, toggleMobileMenu } = useContext(SiteContext)

  const scrollPositions = useRef<Record<string, number>>({})
  const shouldScrollRestore = useRef(false)
  const isInitialLoad = useRef(true)

  useEffect(() => {
    // Prevent browser scroll restoration
    window.history.scrollRestoration = 'manual'
  }, [])

  useEffect(() => {
    function handleBeforeUnload(event: BeforeUnloadEvent) {
      if (!isInitialLoad.current) {
        // Save scroll position
        scrollPositions.current = addScrollPosition(
          scrollPositions.current,
          locale,
          router.asPath,
          window.scrollY
        )
      }

      delete event['returnValue']
    }

    function handleRouteChangeStart(_: string, { shallow }: TransitionOptions) {
      toggleMobileMenu(false)

      if (!isInitialLoad.current) {
        // Save scroll position
        scrollPositions.current = addScrollPosition(
          scrollPositions.current,
          locale,
          router.asPath,
          window.scrollY
        )
      }

      // Check if URL is changing
      if (!shallow) {
        toggleIsRouteChanging(true)
      }
    }

    function handleRouteChangeComplete(
      url: string,
      { shallow }: TransitionOptions
    ) {
      // Wait for page transition to complete
      setTimeout(() => toggleIsRouteChanging(false), pageTransitionSpeed)

      // Check if URL is changing
      if (!isInitialLoad.current && !shallow) {
        // Restore scroll position after route change completes
        const position = scrollPositions.current[`${locale}:${url}`]
        const top = position && shouldScrollRestore.current ? position : 0

        // Restore scroll position or set it to 0
        setTimeout(
          () => requestAnimationFrame(() => window.scrollTo({ top })),
          pageTransitionSpeed + 100
        )

        shouldScrollRestore.current = false
      }

      // Wait for document title to update
      setTimeout(() => triggerPageviewEvent(), pageTransitionSpeed + 101)

      isInitialLoad.current = false
    }

    function handleRouteChangeError() {
      toggleIsRouteChanging(false)
    }

    function handleBeforePopState({ options }: NextHistoryState) {
      // Allow scroll position restoring
      shouldScrollRestore.current = true
      options.scroll = false

      return true
    }

    window.addEventListener('beforeunload', handleBeforeUnload)
    router.events.on('routeChangeStart', handleRouteChangeStart)
    router.events.on('routeChangeComplete', handleRouteChangeComplete)
    router.events.on('routeChangeError', handleRouteChangeError)
    router.beforePopState(handleBeforePopState)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
      router.events.off('routeChangeStart', handleRouteChangeStart)
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
      router.events.off('routeChangeError', handleRouteChangeError)
      router.beforePopState(() => true)
    }
  }, [locale, router, toggleMobileMenu, toggleIsRouteChanging])
}

const Site = ({ pageProps, router, children }: SiteProps) => {
  const { isRouteChanging } = useContext(SiteContext)

  // Handle router events & scroll position restoration
  useRouterEvents(router, pageProps.locale)

  // Handle keyboard navigation
  useEffectOnce(() => {
    function handleKeyDown({ key }: KeyboardEvent) {
      // Check if "tab" key was pressed
      if (key === 'Tab' && typeof window !== 'undefined') {
        document.body.classList.add('is-tabbing')
        window.removeEventListener('keydown', handleKeyDown)
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  })

  // Trigger pageview on page load, if this is the first render and there's no query string
  useEffectOnce(() => {
    if (!router.asPath.includes('?')) {
      triggerPageviewEvent()
    }
  })

  return (
    <div className={cx('font-sans', epilogueFont.variable)}>
      {isRouteChanging && pageProps.site?.strings?.loadingPageTitle && (
        <Head>
          <title>{pageProps.site.strings.loadingPageTitle}</title>
        </Head>
      )}

      <RouteChangeProgressBar />

      <LazyMotion features={domAnimation}>
        <AnimatePresence
          mode="wait"
          onExitComplete={() =>
            document.body.classList.remove('overflow-hidden')
          }
        >
          {children}
        </AnimatePresence>
      </LazyMotion>

      <ExternalScripts settings={pageProps.site?.generalSettings} />
    </div>
  )
}

const CustomApp = ({ Component, pageProps, router }: CustomAppProps) => {
  if (!pageProps.site) {
    return <Component />
  }

  return (
    <StringsContextProvider site={pageProps.site}>
      <SiteContextProvider site={pageProps.site}>
        <ShopContextProvider currency={pageProps.site.cartSettings.currency}>
          <LanguageContextProvider
            locale={pageProps.locale}
            site={pageProps.site}
          >
            <Site pageProps={pageProps} router={router}>
              <Component key={router.asPath.split('?')[0]} {...pageProps} />
            </Site>
          </LanguageContextProvider>
        </ShopContextProvider>
      </SiteContextProvider>
    </StringsContextProvider>
  )
}

export default CustomApp
